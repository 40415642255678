import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Head from "../components/head";
import mediaStyles from "./media.module.scss";
import Video from "../components/video";

const Media = ({data}) => {
  return (
    <Layout>
      <Head title="Media" />
      <article className={mediaStyles.content}>
        <h2>Media Coverage</h2>
        <a href="https://www.fox7austin.com/news/smart-robots-travel-ut-austin-campus-delivering-free-lemonade">FOX 7 Austin</a><br/>
        <a href="https://www.kxan.com/news/local/austin/lemonade-via-robots-ut-research-project-looks-into-responsible-artificial-intelligence/">KXAN Austin</a><br/>
        <a href="https://twitter.com/JCHartzell/status/1331333854347616259">Jay Hartzell on Twitter</a>
      </article>
      <article className={mediaStyles.content}>
        <h2>Demo</h2>
        <Video
          videoSrcURL="https://www.youtube.com/embed/Ja9Mi4QmZA4"
          videoTitle="SMADS Demo"
        />
      </article>
      <article>
        <h2>Demo Slides</h2>
        <a href={data.allFile.edges.find(file => file.node.name === "panel_slides").node.publicURL} >Demo Slides</a>
      </article>
    </Layout>
  );
};

export const query = graphql`
  query {
    allFile(filter: { extension: { eq: "pdf" } }) {
      edges {
        node {
          publicURL
          name
        }
      }
    }
  }
`;

export default Media;
